import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import Banner1 from "../../../Assets/Banner1.png"
import Banner2 from "../../../Assets/Banner2.png"
import Banner3 from "../../../Assets/Banner3.png"
import Banner4 from "../../../Assets/Banner4.png"
import Banner5 from "../../../Assets/Banner5.png"
import Banner6 from "../../../Assets/Banner6.png"
import Banner7 from "../../../Assets/Banner7.png"
import banner_first from "../../../Assets/banner_first.png"
import ban_sm4 from "../../../Assets/ban_sm4.png"
import ban_sm from "../../../Assets/ban_sm.png"
import ban_sm2 from "../../../Assets/ban_sm2.png"
import ban_sm3 from "../../../Assets/ban_sm3.png"
import ban_sm5 from "../../../Assets/ban_sm5.png"
import ban_sm1 from "../../../Assets/ban_sm1.png"
import ban_sm6 from "../../../Assets/ban_sm6.png"
import Banner_mob from "../../../Assets/Banner_mob.png"
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import './Home.css'

const Home = () => {
    return (

        <Swiper
            // pagination={true} 
            loop={true}
            centeredSlides={true}
            pagination={{
                clickable: true,
            }}
            autoplay={{
                delay: 4000,
                disableOnInteraction: true,
            }}
            // dir="rtl"
            className={'home_slider hs1'}
            // slidesPerView={1}  
            breakpoints={{
                200: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                690: {
                    slidesPerView: 1
                },
                1000: {
                    slidesPerView: 1
                },
                1200: {
                    slidesPerView: 1.2
                }
            }}


            spaceBetween={30}
            slidesPerView={1.5}
            modules={[Autoplay, Pagination]}
        >
            {/* <SwiperSlide><img className='img' src={banner_first} alt='Corelens CCTV Camera for Home Security' /><img className='img_mob' src={ban_sm1} alt='Corelens CCTV Camera for Home Security'  /></SwiperSlide> */}
            {/* <SwiperSlide><img className='img' src={Banner1} alt='Corelens CCTV Camera for Home Security'  /><img className='img_mob' src={Banner_mob} alt='Corelens CCTV Camera for Home Security'  /></SwiperSlide> */}
            {/* <SwiperSlide><img className='img' src={Banner2} alt='Corelens CCTV Camera for Home Security'  /><img className='img_mob' src={ban_sm} alt='Corelens CCTV Camera for Home Security'  /></SwiperSlide> */}
            <SwiperSlide><img className='img' src={Banner3} alt='Corelens CCTV Camera for Home Security'  /><img className='img_mob' src={ban_sm2} alt='Corelens CCTV Camera for Home Security'  /></SwiperSlide>
            <SwiperSlide><img className='img' src={Banner4} alt='Corelens CCTV Camera for Home Security'  /><img className='img_mob' src={ban_sm3} alt='Corelens CCTV Camera for Home Security'  /></SwiperSlide>
            <SwiperSlide><img className='img' src={Banner5} alt='Corelens CCTV Camera for Home Security'  /><img className='img_mob' src={ban_sm4} alt='Corelens CCTV Camera for Home Security'  /></SwiperSlide>
            <SwiperSlide><img className='img' src={Banner6} alt='Corelens CCTV Camera for Home Security'  /><img className='img_mob' src={ban_sm5} alt='Corelens CCTV Camera for Home Security'  /></SwiperSlide>
            {/* <SwiperSlide><img className='img' src={Banner7} alt='Corelens CCTV Camera for Home Security'  /><img className='img_mob' src={ban_sm6} alt='Corelens CCTV Camera for Home Security'  /></SwiperSlide> */}

        </Swiper>
    
    );
}

export default Home